import { parse } from '@conform-to/zod';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, HomeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  type HeadersFunction,
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction } from
'@remix-run/node';
import { Link, Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import { withSentry } from '@sentry/remix';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { z } from 'zod';
import { useToast } from './components/toaster.tsx';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { EpicProgress } from './components/progress-bar.tsx';
import { href as iconsHref } from './components/ui/icon.tsx';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx';
import { csrf } from './utils/csrf.server.ts';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { combineHeaders, getDomainUrl, isCurrentPage } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { useRequestInfo } from './utils/request-info.ts';
import { getTheme, setTheme, type Theme } from './utils/theme.server.ts';
import { makeTimings } from './utils/timing.server.ts';
import classNames from 'classnames';
import { getMediaSizeCookie, setMediaSizeCookie } from '#app/utils/media.server.ts';
import { EpicToaster } from './components/ui/sonner.tsx';
import { getToast } from './utils/toast.server.ts';


const navigation = [
{ name: 'built for tax consultants', href: '/', icon: HomeIcon, current: true },
{ name: 'our story', href: 'our-story', icon: HomeIcon, current: false },
{ name: 'client stories', href: 'client-stories', icon: HomeIcon, current: false },
{ name: 'pricing', href: 'pricing', icon: HomeIcon, current: false }];


export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/favicon.png' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/favicon.png' }, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/png', href: '/favicons/favicon.png' },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? 'refundr' : 'Error | refundr' },
  {
    name: 'description',
    content: 'Passionately committed to better connecting tax consultants and their clients.'
  },
  { 'og:description': 'Passionately committed to better connecting tax consultants and their clients.' },
  { 'og:image': 'https://www.refundr.ca/img/refundr.jpg' },
  { 'og:url': 'https://www.refundr.info' },
  { 'og:site': 'https://www.refundr.info' }];

};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader');
  const { toast, headers: toastHeaders } = await getToast(request);
  // @ts-ignore
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();
  const mediaSize = getMediaSizeCookie(request);
  const createdMediaSizeCookie = setMediaSizeCookie('md');

  return json(
    {
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getEnv(),
      toast,
      honeyProps,
      csrfToken
    },
    {
      headers: combineHeaders(
        toastHeaders,
        { 'Server-Timing': timings.toString() },
        csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null,
        !mediaSize ? { 'set-cookie': createdMediaSizeCookie } : null
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark'])
});

export async function action({ request }: LoaderFunctionArgs) {
  const formData = await request.formData();
  const submission = parse(formData, {
    schema: ThemeFormSchema
  });
  if (submission.intent !== 'submit') {
    return json(({ status: 'idle', submission } as const));
  }
  if (!submission.value) {
    return json(({ status: 'error', submission } as const), { status: 400 });
  }
  const { theme } = submission.value;

  const responseInit = {
    headers: { 'set-cookie': setTheme((theme as any)) }
  };
  return json({ success: true, submission }, responseInit);
}

function Document({
  children,
  nonce,
  theme = 'light',
  env = {}





}: {children: React.ReactNode;nonce: string;theme?: Theme;env?: Record<string, string>;}) {
  return (
    <html lang="en" className={`${theme} h-full overflow-x-hidden`} suppressHydrationWarning>
        <head>
            <ClientHintCheck nonce={nonce} />
            {/* Google tag (gtag.js) */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-KJCM8VSFRM"></script>
            <script
          dangerouslySetInnerHTML={{
            __html: `
            				window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments)}
            				gtag('js', new Date()); 
            				gtag('config', 'G-KJCM8VSFRM');`
          }}>
        </script>
            <Meta />
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <meta content="refundr.info" property="og:site_name" />
            <meta content="https://www.refundr.info" property="og:site" />
            <meta content="refundr" property="og:title" />
            <meta content="Tax return and client management software" property="og:description" />
            <meta content="https://www.refundr.info/img/refundr.jpg" property="og:image" />
            <meta content="https://www.refundr.info" property="og:url" />
            <meta content="article" property="og:type" />
            <Links />
        </head>
        <body className="bg-background text-foreground">
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <LiveReload nonce={nonce} />
        </body>
        </html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const theme = useTheme();
  useToast(data.toast);
  return (
    <Document nonce={nonce} theme={theme} env={data.ENV}>
            <div className="flex h-screen flex-col justify-between">
                <div className="min-h-full">
                    {/* NAV START */}
                    <Disclosure as="nav" className="border-b border-gray-200 bg-white shadow-sm dark:shadow-none">
                        {({ open }) =>
            <>
                                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                                    <div className="flex h-16 justify-between">
                                        <div className="flex">
                                            {/* LOGO START */}
                                            <div className="flex flex-shrink-0 items-center">
                                                <Link to={'/'} onClick={() => isCurrentPage(navigation, null)}>
                                                    <img className="block h-6 w-auto lg:hidden"
                        src="/img/logo/logo-black.png" alt="refundr" />
                                                </Link>
                                                <Link to={'/'} onClick={() => isCurrentPage(navigation, null)}>
                                                    <img className="hidden h-6 w-auto lg:block"
                        src="/img/logo/logo-black.png" alt="refundr" />
                                                </Link>
                                            </div>
                                            {/* LOGO END */}

                                            {/* SITE NAV START */}
                                            <div className="hidden sm:-my-px sm:ml-20 sm:flex sm:space-x-8">
                                                {navigation.map((item) =>
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => isCurrentPage(navigation, item.name)}
                        className={classNames(
                          item.current ?
                          'border-indigo-500 text-gray-900' :
                          'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}>

                                                        {item.name}
                                                    </Link>
                      )}
                                                <div className="pl-10 text-black flex items-center">
                                                    <a
                          href="https://dashboard.refundr.ca/signup"
                          className="whitespace-nowrap rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          target="_blank">

                                                        Try for free in minutes
                                                    </a>
                                                </div>
                                            </div>
                                            {/* SITE NAV END */}
                                        </div>

                                        {/* USER NAV START */}

                                        <div></div>


                                        {/* USER NAV END */}

                                        {/* NAV ICON START */}
                                        <div className="-mr-2 flex items-center sm:hidden">
                                            <Disclosure.Button
                      className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                <span className="absolute -inset-0.5" />
                                                <span className="sr-only">Open main menu</span>
                                                {open ?
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> :

                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}

                                            </Disclosure.Button>
                                        </div>
                                        {/* NAV ICON END */}
                                    </div>
                                </div>

                                {/* MOBILE MENU START */}
                                <Disclosure.Panel className="sm:hidden">
                                    {/* MOBILE NAV START */}
                                    <div className="space-y-1 pb-3 pt-2">
                                        {navigation.map((item) =>
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ?
                      'border-indigo-500 bg-indigo-50 text-indigo-700' :
                      'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                      'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}>

                                                {item.name}
                                            </Disclosure.Button>
                  )}
                                    </div>
                                    {/* MOBILE NAV END */}

                                    {/* MOBILE USER NAV START */}

                                    <div className="border-t border-gray-200 pb-3 pt-4">
                                        <div className="flex items-center px-4">
                                            <div className="ml-3">
                                                <div className="text-base font-medium text-gray-800">
                                                    Menu
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* MOBILE USER NAV END */}
                                </Disclosure.Panel>
                                {/* MOBILE MENU END */}
                            </>}

                    </Disclosure>
                    {/* NAV END */}

                    {/* MAIN DIV START */}
                    <div className="min-h-full">
                        <main>
                            <Outlet />
                        </main>
                    </div>
                    {/* FOOTER */}
                    <div className="mx-auto max-w-2xl px-6 text-center sm:px-0 text-gray-800 pb-32">
                        <div className="pt-3">
                            <img className="block h-6 w-auto my-10"
              src="/img/logo/logo-refundr.png" alt="refundr" />
                        </div>
                        <div className="grid grid-cols-2 gap-20 md:flex md:gap-28 md:py-5">
                            <div>
                                <div className="font-mono text-sm text-left">
                                    <div className="mb-6 font-bold ">product</div>
                                    <div className="flex flex-col gap-3">
                                        <div><a href="/">Tax Consultants</a></div>
                                        <div><a href="/client-stories">Customer Stories</a>
                                        </div>
                                        <div><a href="/pricing">Pricing</a></div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="font-mono text-sm text-left">
                                    <div className="mb-6 font-bold ">company</div>
                                    <div className="flex flex-col gap-3">
                                        <div><a href="/our-story">Our Story</a></div>
                                        <div><a href="/terms">Terms</a></div>
                                        <div><a href="/privacy-policy">Privacy Policy</a></div>
                                    </div>
                                </div>
                            </div>
                            {/*<div>*/}
                            {/*    <div className="font-mono text-sm text-left">*/}
                            {/*        <div className="mb-6 font-bold ">social</div>*/}
                            {/*        <div className="flex flex-col gap-3">*/}
                            {/*            <div><a href="https://www.linkedin.com/company/71683808"*/}
                            {/*                    target="_blank">LinkedIn</a></div>*/}
                            {/*            <div><a href="https://www.facebook.com/refundr.for.canadians"*/}
                            {/*                    target="_blank">Facebook</a></div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {/* MAIN DIV END */}
                </div>
            </div>
            <EpicToaster closeButton position="top-center" theme={theme} />
            <EpicProgress />
        </Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
            <HoneypotProvider {...data.honeyProps}>
                <App />
            </HoneypotProvider>
        </AuthenticityTokenProvider>);

}

export default withSentry(AppWithProviders);

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints();
  const requestInfo = useRequestInfo();
  return requestInfo.userPrefs.theme ?? hints.theme;
}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
            <GeneralErrorBoundary />
        </Document>);

}